import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TextButton} from 'wix-ui-tpa/TextButton'
import ChevronRightSmall from 'wix-ui-icons-common/on-stage/ChevronRightSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {classes} from './ticket-action.st.css'
import {TicketActionProps} from '.'

export const TicketAction = ({
  showAccessibilityMode,
  inDescription,
  selected,
  ticketDescription,
  area,
  onClick,
}: TicketActionProps) => {
  const {t} = useTranslation()

  if (showAccessibilityMode) {
    return area ? null : (
      <TextButton
        data-hook={DH.ADD}
        className={!selected ? classes.addButton : classes.moreInfo}
        prefixIcon={selected ? <DeleteSmall /> : undefined}
      >
        {selected ? t('seatings_removeFromBasket') : t('seatings_addToBasket')}
      </TextButton>
    )
  }

  return !inDescription && ticketDescription ? (
    <TextButton
      data-hook={showAccessibilityMode ? DH.ADD : DH.MORE_BUTTON}
      className={showAccessibilityMode && !selected ? classes.addButton : classes.moreInfo}
      suffixIcon={showAccessibilityMode ? undefined : <ChevronRightSmall />}
      prefixIcon={showAccessibilityMode && selected ? <DeleteSmall /> : undefined}
      onClick={onClick}
    >
      {t('seatings_ticket_moreInfo')}
    </TextButton>
  ) : null
}
