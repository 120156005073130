import * as React from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {Text} from 'wix-ui-tpa/Text'
import {SlideInModal} from '../../slide-in-modal'
import {Header} from '../../common/header'
import {Ticket} from '../ticket'
import {classes} from './ticket-description.st.css'
import {TicketDescriptionProps} from '.'

export const TicketDescription = ({selectedSeatForDescription, onClose}: TicketDescriptionProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  return (
    <SlideInModal
      className={isMobile ? undefined : classes.desktopSlideInModal}
      show={Boolean(selectedSeatForDescription)}
      fromBottom={isMobile}
      onBackClick={isMobile ? undefined : onClose}
      backButtonText={isMobile ? undefined : t('seatings_moreInfo_backToBasket')}
    >
      <div>
        <Header
          className={isMobile ? undefined : classes.desktopHeader}
          titleClassName={isMobile ? undefined : classes.desktopTitle}
          title={t('seatings_moreInfo_title')}
          closeModal={isMobile ? onClose : null}
        />
        <div className={classes.content}>
          <Ticket inDescription={true} place={selectedSeatForDescription} />
          <Text className={classes.description}>{selectedSeatForDescription?.ticketDescription}</Text>
        </div>
      </div>
    </SlideInModal>
  )
}
