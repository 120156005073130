import classNames from 'classnames'
import React from 'react'
import c from '../../../classnames.scss'
import {InvoiceBreakdown, InvoiceBreakdownSize} from '../../../invoice-breakdown'
import {TotalPriceProps} from './interfaces'
import s from './total-price.scss'

export const TotalPrice: React.FC<TotalPriceProps> = ({soldOut, t}) =>
  soldOut ? (
    <SoldOut t={t} />
  ) : (
    <InvoiceBreakdown
      t={t}
      containerClass={classNames(s.container, c.evTextFont, c.evTextColor)}
      size={InvoiceBreakdownSize.big}
    />
  )

const SoldOut = ({t}) => (
  <div className={classNames(c.evTextFont, c.evTextColor)} data-hook="event-sold-out">
    <span className={s.soldOutLabel}>{t('ticketsPicker.soldOut')}</span>
  </div>
)
