import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {getBestPurchasedPlan, getSelectedPaidPlan} from '../../../../../selectors/paid-plans'
import {getSelectedTicketsWithPlaces} from '../../../../../selectors/seating'
import {FooterRuntimeProps, FooterOwnProps} from './interfaces'
import {Footer as Presentation} from './footer'

const mapRuntime = ({state, actions: {setShowAccessibilityMode}}: DetailsPageAppProps): FooterRuntimeProps => ({
  event: state.event,
  tickets: state.tickets,
  paidPlan: getSelectedPaidPlan(state) || getBestPurchasedPlan(state),
  selectedTicketsWithPlacesCount: getSelectedTicketsWithPlaces(state).length,
  selectedTickets: state.selectedTickets,
  showAccessibilityMode: state.seating.showAccessibilityMode,
  setShowAccessibilityMode,
})

export const Footer = connect<FooterOwnProps, FooterRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
