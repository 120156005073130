import {TFunction} from '@wix/yoshi-flow-editor'
import React from 'react'

export interface InvoiceBreakdownOwnProps {
  t: TFunction
  containerClass?: string
  size?: InvoiceBreakdownSize
  divider?: React.ReactNode
}

export interface InvoiceBreakdownProps extends InvoiceBreakdownRuntimeProps, InvoiceBreakdownOwnProps {}

export interface InvoiceBreakdownRuntimeProps {
  invoice: wix.events.ticketing.Invoice
}

export enum InvoiceBreakdownSize {
  small,
  big,
}
