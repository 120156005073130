import {SelectedTickets, State} from '../types'
import {getTicketOrderIndexById, getTicketById} from './tickets'

export const getSelectedTicketsQuantity = (state: SelectedTickets) =>
  Object.keys(state).reduce((sum: number, ticketId: string) => state[ticketId].quantity + sum, 0)

export const getSelectedTicketQuantity = (state: SelectedTickets, ticketId: string, seatId?: string) => {
  if (seatId) {
    return (state[ticketId]?.placeIds ?? []).filter(id => id === seatId).length
  }
  return state[ticketId]?.quantity ?? 0
}

export const getSortedSelectedTickets = (state: State): SelectedTickets => {
  const nonZero = (ticketDefId: string) => Boolean(state.selectedTickets[ticketDefId]?.quantity)

  const byTicketOrder = (first: string, second: string) =>
    getTicketOrderIndexById(state.tickets, first) - getTicketOrderIndexById(state.tickets, second)

  return Object.keys(state.selectedTickets)
    .filter(nonZero)
    .sort(byTicketOrder)
    .reduce(
      (selectedTickets: SelectedTickets, id: string) => ({
        ...selectedTickets,
        [id]: state.selectedTickets[id],
      }),
      {},
    )
}

export const getSelectedTickets = (state: State): wix.events.ticketing.Ticket[] =>
  Object.entries(getSortedSelectedTickets(state)).reduce((tickets, [ticketDefId, {quantity}]) => {
    const ticket = getTicketById(state.tickets, ticketDefId)
    tickets.push(...Array(quantity).fill(ticket))
    return tickets
  }, [])

export const getTotalOrderRevenue = (state: State) =>
  Object.keys(state.selectedTickets).reduce(
    (sum, ticketId) =>
      sum + Number(getTicketById(state.tickets, ticketId).price.amount) * state.selectedTickets[ticketId].quantity,
    0,
  )

export const getNonFreeSelectedTicketsCount = (state: State) =>
  Object.keys(state.selectedTickets)
    .filter(ticketId => state.tickets.find(ticket => ticket.id === ticketId && !ticket.free))
    .reduce((sum, ticketId) => sum + state.selectedTickets[ticketId].quantity, 0)
