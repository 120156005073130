import {Place, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import React, {useCallback, useEffect, useMemo} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {Modal} from '../modal'
import {getPlaceQuantity} from '../../../selectors/seating'
import {Loader} from './loader'
import {Mobile} from './mobile'
import {Desktop} from './desktop'
import {classes} from './seating-plan.st.css'
import {SeatingPlanProps} from '.'

export const SeatingPlan = ({
  addPlaceToBasket,
  checkout,
  loading,
  placesInBasket,
  showAccessibilityMode,
  chooseSeatMode,
  setShowAccessibilityMode,
  selectedPlace,
  unselectPlace,
  selectPlace,
}: SeatingPlanProps) => {
  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const desktop = !mobile

  useEffect(() => {
    if (chooseSeatMode) {
      window.parent.postMessage({placesInBasket}, '*')
    }
  }, [placesInBasket])

  const handleAddClick = useCallback(
    (amount: number = 1) => {
      addPlaceToBasket({
        placeId: selectedPlace.id,
        count: amount,
      })
    },
    [selectedPlace],
  )

  const handleCheckout = useCallback(() => checkout(), [checkout])

  const onPlaceClick = useCallback(
    (place: Place) => {
      const currentCount = getPlaceQuantity(placesInBasket, place.id)
      if (desktop || showAccessibilityMode || currentCount) {
        if (place.elementType === Type.AREA) {
          addPlaceToBasket({placeId: place.id, count: currentCount + 1})
        } else {
          addPlaceToBasket({placeId: place.id, count: currentCount ? 0 : 1})
        }
      } else {
        selectedPlace?.id === place.id ? unselectPlace({placeId: place.id}) : selectPlace({placeId: place.id})
      }
    },
    [showAccessibilityMode, placesInBasket, selectedPlace],
  )

  const onRemoveCLick = useCallback(
    (place: PlaceWithTicketInfo) => {
      const currentCount = getPlaceQuantity(placesInBasket, place.id)
      addPlaceToBasket({placeId: place.id, count: currentCount - 1})
    },
    [placesInBasket],
  )

  const mobileTabs = useMemo(
    () => [{title: t('seatingMapModal_mapTab_title')}, {title: t('seatingMapModal_listTab_title')}],
    [t],
  )

  const onTabClick = useCallback(() => setShowAccessibilityMode(!showAccessibilityMode), [showAccessibilityMode])

  const onCloseClick = useCallback(() => {
    if (selectedPlace?.id) {
      unselectPlace({placeId: selectedPlace.id})
    }
  }, [selectedPlace])

  return (
    <Modal
      className={classNames(
        desktop && !chooseSeatMode && classes.desktopModal,
        chooseSeatMode && classes.hideCloseButton,
      )}
      closeButtonAsButton={desktop}
      dataHook={DH.SEATINGS_MODAL}
      title={mobile ? t('seatingMapModal_title') : undefined}
      withoutPadding={desktop}
      tabs={mobile ? mobileTabs : undefined}
      activeTabIndex={showAccessibilityMode ? 1 : 0}
      onTabClick={onTabClick}
    >
      {loading ? <Loader /> : null}
      {mobile ? (
        <Mobile
          onRemoveClick={onRemoveCLick}
          onCheckoutClick={handleCheckout}
          onPlaceClick={onPlaceClick}
          onCloseClick={onCloseClick}
          onAddClick={handleAddClick}
        />
      ) : (
        <Desktop
          onPlaceClick={onPlaceClick}
          onRemoveClick={onRemoveCLick}
          onCheckoutClick={handleCheckout}
          showAccessibilityMode={showAccessibilityMode}
        />
      )}
    </Modal>
  )
}
