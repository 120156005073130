import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {getFilteredPlaces} from '../../../../selectors/seating'
import {AccessibilityModeRuntimeProps, AccessibilityModeOwnProps} from './interfaces'
import {AccessibilityMode as Presentation} from './accessibility-mode'

const mapRuntime = ({
  state,
  actions: {setShowAccessibilityMode},
}: DetailsPageAppProps): AccessibilityModeRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  setShowAccessibilityMode,
  places: getFilteredPlaces(state),
})

export const AccessibilityMode = connect<AccessibilityModeOwnProps, AccessibilityModeRuntimeProps>(mapRuntime)(
  Presentation,
)

export * from './interfaces'
