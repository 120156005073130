import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {SummaryContainer} from '../../summary-container'
import {InnerSummary} from '../../inner-summary'
import {classes} from './footer.st.css'
import {FooterProps} from '.'

export const Footer = ({
  selectedTicketsWithPlacesCount,
  tickets,
  event,
  paidPlan,
  selectedTickets,
  showAccessibilityMode,
  setShowAccessibilityMode,
  onButtonClick,
}: FooterProps) => {
  const {t} = useTranslation()
  const handleButtonClick = () => (onButtonClick ? onButtonClick() : setShowAccessibilityMode(false))

  return (
    <SummaryContainer inAccessibilityMode={showAccessibilityMode}>
      <InnerSummary
        invoice={getPreliminaryInvoice(tickets, selectedTickets, getTaxConfig(event), paidPlan)}
        selectedTickets={selectedTicketsWithPlacesCount}
        withoutBreakdown
      />
      <Button data-hook={DH.VIEW_BASKET} upgrade className={classes.button} onClick={handleButtonClick}>
        {t('seatings_viewBasket')}
      </Button>
      <div data-hook={DH.ACCESSIBILITY_MODE_END} tabIndex={0} />
    </SummaryContainer>
  )
}
