import {Category, Place, Sector} from '@wix/ambassador-seating-v1-seating-plan/types'
import {createAction, createAsyncThunk} from '@reduxjs/toolkit'
import {
  getEventId,
  getFormattedMoney,
  WIX_EVENTS_TICKET_DEFINITION_FQDN,
  PlaceWithTicketInfo,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {getFee, getTax} from '../selectors/tax-and-fee'
import {SeatingState} from '../types'
import {getSelectedTicketQuantity} from '../selectors/selected-tickets'
import {isChooseSeatMode} from '../selectors/navigation'
import {ThunkConfig} from './interfaces'
import {SelectUnselectTicket} from './selected-tickets'

export const describeSeatingPlan = createAsyncThunk<Partial<SeatingState>, void, ThunkConfig>(
  'DESCRIBE_SEATING_PLAN',
  async (_, {getState, extra: {serverApi, flowAPI}}) => {
    const t: TFunction = flowAPI.translations.t as TFunction
    const {tickets, event} = getState()
    const response = await serverApi.describeSeatingPlan(getEventId(event))
    const sectors = response.plan.sectors

    const placesWithInfo: PlaceWithTicketInfo[] = response.plan.categories
      .filter(category => category.totalCapacity)
      .reduce(
        (places, category) => [
          ...places,
          ...category.places
            .filter(place => place.capacity)
            .map(place => getPlaceWithTicketInfo({place, category, sectors, tickets, event, t})),
        ],
        [] as PlaceWithTicketInfo[],
      )

    return {
      plan: response.plan,
      planReservations: response.planReservations,
      places: placesWithInfo,
    }
  },
)

export const selectPrice = createAsyncThunk<string, string, ThunkConfig>('SELECT_PRICE', selectedPrice => {
  return selectedPrice
})

export const addPlaceToBasket = createAsyncThunk<
  {places: PlaceWithTicketInfo[]; ticket: SelectUnselectTicket},
  {placeId: string; count: number},
  ThunkConfig
>('ADD_PLACE_TO_BASKET', ({placeId, count}, {getState}) => {
  const state = getState()
  const chooseSeatMode = isChooseSeatMode(state)
  const currentPlace = state.seating.places.find(({id}) => id === placeId)

  const ticket: SelectUnselectTicket = {
    ticketId: currentPlace.ticketId,
    count: getSelectedTicketQuantity(state.selectedTickets, currentPlace.ticketId) - currentPlace.quantity + count,
    placeIds: chooseSeatMode
      ? [currentPlace.id]
      : [
          ...(state.selectedTickets[currentPlace.ticketId]?.placeIds ?? []).filter(id => id === placeId),
          ...new Array(count).fill(currentPlace.id),
        ],
  }

  return {
    places: state.seating.places.map(place =>
      place.id === placeId
        ? {
            ...place,
            quantity: count,
            inBasket: Boolean(count),
            selected: false,
          }
        : chooseSeatMode
        ? {...place, quantity: 0, inBasket: false, selected: false}
        : place,
    ),
    ticket,
  }
})

export const selectPlace = createAsyncThunk<{places: PlaceWithTicketInfo[]}, {placeId: string}, ThunkConfig>(
  'SELECT_PLACE',
  ({placeId}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place =>
        place.id === placeId
          ? {
              ...place,
              selected: true,
            }
          : place,
      ),
    }
  },
)

export const unselectPlace = createAsyncThunk<{places: PlaceWithTicketInfo[]}, {placeId: string}, ThunkConfig>(
  'UNSELECT_PLACE',
  ({placeId}, {getState}) => {
    const state = getState()

    return {
      places: state.seating.places.map(place =>
        place.id === placeId
          ? {
              ...place,
              selected: false,
            }
          : place,
      ),
    }
  },
)

export const selectLocation = createAction<string>('SELECT_LOCATION')

export const setShowAccessibilityMode = createAction<boolean>('SET_SHOW_ACCESSIBILITY_MODE')

const getPlaceWithTicketInfo = ({
  place: {id, label, capacity, elementType},
  category: {
    externalId,
    config: {color},
  },
  sectors,
  tickets,
  event,
  t,
}: GetPlaceWithTicketInfoParams): PlaceWithTicketInfo => {
  const ticketDefinition = tickets.find(
    ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
  )
  const [sectorId, elementId] = id.split('-')
  const sector = sectors.find(item => item.id === Number(sectorId))
  const element = sector.elements.find(item => item.id === Number(elementId))

  return {
    id,
    elementType,
    label,
    color,
    capacity,
    quantity: 0,
    selected: false,
    inBasket: false,
    sector: sectorId === '0' ? undefined : sector.title,
    elementLabel: element.title,
    elementId: element.id,
    ticketId: ticketDefinition.id,
    ticketName: ticketDefinition.name,
    ticketDescription: ticketDefinition.description,
    ticketPrice: getFormattedMoney(ticketDefinition.price),
    fees: [getTax(event, ticketDefinition, t), getFee(event, ticketDefinition, t)].filter(Boolean).join(', '),
  }
}

interface GetPlaceWithTicketInfoParams {
  place: Place
  category: Category
  sectors: Sector[]
  tickets: wix.events.ticketing.TicketDefinition[]
  event: wix.events.Event
  t: TFunction
}
