import classNames from 'classnames'
import React from 'react'
import s from './table-row.scss'
import {RowConfig, TableRowType, TableRowProps} from '.'

export const TableRow: React.FC<TableRowProps> = ({config}) => {
  const {type: style, dataHook, asText, addDivider, className, responsiveHideColumns = []} = config

  return (
    <>
      <div
        className={classNames(s.row, className, {
          [s.small]: style === TableRowType.small,
          [s.large]: style === TableRowType.large,
          [s[`reducedHidesColumn-${responsiveHideColumns.join('-')}`]]: responsiveHideColumns.length,
        })}
        data-hook={dataHook}
        role={asText ? 'text' : 'row'}
      >
        {generateColumns(config)}
      </div>
      {addDivider && (
        <div
          className={classNames(s.dividerRow, {
            [s[`reducedHidesColumn-${responsiveHideColumns.join('-')}`]]: responsiveHideColumns.length,
          })}
        >
          {generateColumns(config, true)}
        </div>
      )}
    </>
  )
}

const generateColumns = (config: RowConfig, divider = false) => {
  let columnRole = config.type === TableRowType.header ? 'columnheader' : 'cell'

  if (config.asText) {
    columnRole = null
  }

  const {dividerClassName: globalDividerClassName} = config

  let columnIndex = 0

  return config.columns.map(column => {
    if (column) {
      const {className, contentClassName, dividerClassName, content, dataHook, ariaLabel} = column

      return (
        <div
          key={`${config.dataHook}-column-${columnIndex}`}
          className={classNames(s.column, s[`column-${columnIndex++}`], {
            [s.defaultColumn]: !className,
            [className]: className,
          })}
          role={divider ? null : columnRole}
        >
          <div
            className={classNames({[s.columnContent]: !divider, [contentClassName]: !divider})}
            data-hook={(!divider && dataHook) || null}
            aria-label={ariaLabel}
          >
            {divider ? <div className={classNames(globalDividerClassName || s.divider, dividerClassName)} /> : content}
          </div>
        </div>
      )
    } else {
      return <div key={`${config.itemKey}-column-${columnIndex}`} className={s[`column-${columnIndex++}`]} />
    }
  })
}
