import {SeatingPlan} from '@wix/ambassador-seating-v1-seating-plan/types'
import {getScaledViewBox, round, getInitialViewBox} from '@wix/seating-common-components'
import {useState, PointerEventHandler} from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'

const getPointFromEvent = event => {
  const point = {x: 0, y: 0}

  if (event.targetTouches) {
    point.x = event.targetTouches[0].clientX
    point.y = event.targetTouches[0].clientY
  } else {
    point.x = event.clientX
    point.y = event.clientY
  }

  return point
}

interface ChangeSvgViewBoxArgs {
  deltaX?: number
  deltaY?: number
  deltaScale?: number
}

export const useViewBox = (plan: SeatingPlan = {}, container: HTMLDivElement) => {
  const {isMobile} = useEnvironment()
  const [pointerDown, setPointerDown] = useState(false)
  const [x, y, width, height] = getInitialViewBox(plan).split(' ').map(Number)
  const [viewBox, setViewBox] = useState(`${x} ${y} ${width} ${height}`)
  const [startCoordinates, setStartCoordinates] = useState({x: 0, y: 0})
  const [noCompensationCoordinates, setNoCompensationCoordinates] = useState({x, y})
  const [zoom, setZoom] = useState(1)
  const [cursor, setCursor] = useState('grab')

  const changeSvgViewBox = ({deltaX = 0, deltaY = 0, deltaScale = 0}: ChangeSvgViewBoxArgs) => {
    const {x: noCompensationX, y: noCompensationY} = noCompensationCoordinates

    let minX = noCompensationX - deltaX
    let minY = noCompensationY - deltaY
    const scale = round(zoom + deltaScale, 0.1)

    // Moving view
    const {centeringX, centeringY, width: centeringWidth, height: centeringHeight} = getScaledViewBox({
      scale,
      container: {
        clientHeight: height,
        clientWidth: width,
      } as SVGElement,
    })

    setNoCompensationCoordinates({x: minX, y: minY})
    setZoom(scale)

    minX -= centeringX
    minY -= centeringY

    setViewBox(`${minX} ${minY} ${centeringWidth} ${centeringHeight}`)
  }

  const onPointerDown: PointerEventHandler<HTMLDivElement> = event => {
    const pointerPosition = getPointFromEvent(event)
    setStartCoordinates({x: pointerPosition.x, y: pointerPosition.y})
    setPointerDown(true)
    setCursor('grabbing')
  }

  const onPointerMove: PointerEventHandler<HTMLDivElement> = event => {
    event.preventDefault()

    if (!pointerDown) {
      return
    }

    const {x: mouseX, y: mouseY} = getPointFromEvent(event)
    const {x: startX, y: startY} = startCoordinates
    const containerHeight = container.getBoundingClientRect().height
    const widthRatio = round(width / container.getBoundingClientRect().width)
    const heightRatio = round(
      height / (isMobile ? containerHeight - parseInt(getComputedStyle(container).paddingBottom, 10) : containerHeight),
    )
    const ratio = widthRatio > heightRatio ? widthRatio : heightRatio
    const fixedZoom = round(zoom)

    const dx = (mouseX - startX) * ratio
    const dy = (mouseY - startY) * ratio

    changeSvgViewBox({
      deltaX: round(dx / fixedZoom, 0.00001),
      deltaY: round(dy / fixedZoom, 0.00001),
    })

    setStartCoordinates({x: mouseX, y: mouseY})
  }

  const onPointerUp = () => {
    setStartCoordinates({x: 0, y: 0})
    setPointerDown(false)
    setCursor('grab')
  }

  return {
    viewBox,
    zoom,
    cursor,
    changeSvgViewBox,
    onPointerDown,
    onPointerMove,
    onPointerUp,
  }
}
