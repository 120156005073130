import React from 'react'
import classNames from 'classnames'
import {Divider} from '../../common/divider'
import {OrderSuccessProps} from '../index'
import c from '../../../classnames.scss'
import {Invoice} from './invoice'
import s from './mobile.scss'

export const Mobile: React.FC<OrderSuccessProps> = ({
  order: {orderNumber, invoice},
  formattedOrderCreatedDate,
  t,
  navigateToMainPage,
}) => (
  <div className={classNames(c.formLabelsColor, c.evTextFont)}>
    <div className={s.header}>
      <div className={s.orderNumber}>{t('ticketsThankYou.orderNo', {orderNumber})}</div>
    </div>
    <Divider />
    <div className={s.placedOn}>{t('ticketsThankYou.placedOn', {date: formattedOrderCreatedDate})}</div>
    <Divider />
    <Invoice t={t} invoice={invoice} />
    <Divider />
    <button className={s.navigate} onClick={() => navigateToMainPage()}>
      {t('backToSite')}
    </button>
  </div>
)
