import React, {useCallback, useMemo, useState} from 'react'
import {Plan as PlanViewer} from '@wix/seating-viewer'
import {PlanReservations} from '@wix/ambassador-seating-v1-seating-plan/build/es/types'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {useViewBox} from '../../../../hooks/viewbox'
import {PopoverData, SeatingPopover} from '../seating-popover'
import {getPlaceInfo} from '../../../../selectors/seating'
import {Controls} from './controls'
import {Legend} from './legend'
import s from './plan.scss'
import {PlanProps} from '.'

const MIN_ZOOM = 0.2
const MAX_ZOOM = 2
const ZOOM_STEP = 0.2

const calculatePlacesStock = (places: PlaceWithTicketInfo[], planReservations: PlanReservations) => {
  return places.reduce((acc, place) => {
    acc[place.id] = place.capacity - place.quantity - planReservations.reservations[place.id].occupied
    return acc
  }, {} as Record<string, number>)
}

export const Plan = ({plan, placesInBasket, onPlaceClick, planReservations, places, selectedPlace}: PlanProps) => {
  const {isMobile} = useEnvironment()
  const container = React.useRef<HTMLDivElement>(null)
  const {viewBox, zoom, cursor, changeSvgViewBox, onPointerDown, onPointerMove, onPointerUp} = useViewBox(
    plan,
    container.current,
  )
  const [popoverData, setPopoverData] = useState(null as PopoverData)
  const placesStock = useMemo(() => calculatePlacesStock(places, planReservations), [plan, planReservations])

  const onPlaceMouseEnter = useCallback(
    params => {
      const info = getPlaceInfo(places, params.seat.id)

      if (!info) {
        return null
      }

      const containerBoundingClientRect = container.current.getBoundingClientRect()
      const boundingClientRect = params.event.currentTarget.getBoundingClientRect()

      setPopoverData({boundingClientRect, info, containerBoundingClientRect} as PopoverData)
    },
    [places],
  )

  const onPlaceMouseLeave = useCallback(() => {
    setPopoverData(null)
  }, [])

  const selectedPlacesIds = useMemo(() => placesInBasket.map(place => place.id), [placesInBasket])

  return (
    <div
      ref={container}
      className={classNames(s.container, {[s.mobileContainer]: isMobile})}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerLeave={onPointerUp}
      onPointerMove={onPointerMove}
      style={{cursor}}
    >
      <PlanViewer
        onPlaceMouseLeave={isMobile ? null : onPlaceMouseLeave}
        onPlaceMouseEnter={isMobile ? null : onPlaceMouseEnter}
        placesStock={placesStock}
        currentlyClickedPlaceId={selectedPlace?.id}
        selectedPlaceIds={selectedPlacesIds}
        plan={plan}
        svgViewBox={viewBox}
        onPlaceClick={onPlaceClick}
        mobile={isMobile}
      />
      {isMobile ? null : <Legend />}
      <Controls
        zoomOutDisabled={zoom === MIN_ZOOM}
        zoomInDisabled={zoom === MAX_ZOOM}
        onZoomIn={() => changeSvgViewBox({deltaScale: ZOOM_STEP})}
        onZoomOut={() => changeSvgViewBox({deltaScale: -ZOOM_STEP})}
      />
      {popoverData ? <SeatingPopover popoverData={popoverData} /> : null}
    </div>
  )
}
