import React from 'react'
import {Container} from '../common/container'
import {Title} from '../common/title'
import s from './order-expired.scss'
import {OrderExpiredProps} from '.'

export const OrderExpired: React.FC<OrderExpiredProps> = ({t, navigateToMainPage}) => (
  <Container dataHook="order-expired">
    <div style={{textAlign: 'center'}}>
      <Title text={t('pageExpired')} />
      <button className={s.link} data-hook="back-to-site" onClick={() => navigateToMainPage()}>
        {t('backToSite')}
      </button>
    </div>
  </Container>
)
