import {asyncActionsFactory} from '@wix/wix-events-commons-statics'
import {FlowAPI, IPlatformAPI, IWixAPI} from '@wix/yoshi-flow-editor'
import {State} from '../types'
import {Api} from '../utils/api'

type Extra = {
  api: Api
  wixCodeApi: IWixAPI
  flowAPI: FlowAPI
  platformAPIs: IPlatformAPI
}

export const createAsyncAction = asyncActionsFactory<State, Extra>()
