import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {LabelWithValue} from '../../../common/label-with-value'
import c from '../../../classnames.scss'
import s from './seat.scss'
import {SeatProps} from '.'

export const Seat = ({seatingPlan, ticket, seatId}: SeatProps) => {
  const {t} = useTranslation()
  const [sectorId, rowId] = seatId.split('-')
  const sector = seatingPlan.sectors.find(item => item.id === Number(sectorId))
  const row = sector.elements.find(item => item.id === Number(rowId))
  const place = row.places.find(item => item.id === seatId)
  const labelWithValueClassNames = {
    valueClassName: c.formSummaryColor,
    labelClassName: c.formSummaryColor,
  }

  return (
    <div className={s.container}>
      <div className={s.infoContainer}>
        <LabelWithValue label={t('seatings_sector')} value={sector.title} {...labelWithValueClassNames} />
        <div className={s.rowAndSeat}>
          <LabelWithValue label={t('seatings_row')} value={row.title} {...labelWithValueClassNames} />
          <LabelWithValue label={t('seatings_seat')} value={place.label} {...labelWithValueClassNames} />
        </div>
      </div>
      <div className={s.ticketInfoContainer}>
        <span>{ticket.name}</span>
        <span>{getFormattedMoney(ticket.price)}</span>
      </div>
    </div>
  )
}
