import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import c from '../../../../classnames.scss'
import {getFee} from '../../../../../selectors/tax-and-fee'
import s from './wix-fee.scss'
import {WixFeeProps} from '.'

export const WixFee = ({ticket, t, event, className}: WixFeeProps) => {
  const fee = getFee(event, ticket, t)

  if (!fee) {
    return null
  }

  return (
    <div
      className={classNames(c.evTicketAdditionalInfoColor, s.wixFee, className)}
      data-hook={DH.WIX_FEE}
      aria-label={fee}
    >
      {fee}
    </div>
  )
}
