import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {classes} from './header.st.css'

export const Header = () => {
  const {t} = useTranslation()

  return (
    <div className={classes.container} tabIndex={0}>
      <Text className={classes.title}>{t('seatings_accessibility_title')}</Text>
    </div>
  )
}
