import {ITrackEventName, ITrackEventParams, IWixAPI} from '@wix/yoshi-flow-editor'
import {
  DEFAULT_LANGUAGE,
  EVENTS_APP_ID,
  SCHEDULE_SECTION_ID,
  isLanguageBabelSupported,
  EVENTS_WEB_API_BASE,
  MAIN_DC_URL,
} from '@wix/wix-events-commons-statics'

export const getSiteStructure = (wixCodeApi: IWixAPI) => wixCodeApi.site.getSiteStructure({includePageId: true})

export const getPageInfo = async (wixCodeApi: IWixAPI) => {
  try {
    const siteStructure = await getSiteStructure(wixCodeApi)
    const homePage = siteStructure.pages.find(page => page?.isHomePage)
    return {isHomePage: homePage?.id === wixCodeApi.site.currentPage?.id}
  } catch (e) {
    console.log(e)
    return {isHomePage: false}
  }
}

export const isSSR = (wixCodeApi: IWixAPI) => wixCodeApi.window.rendering.env === 'backend'

export const getServerBaseUrl = (wixCodeApi: IWixAPI) => `${getBaseUrl(wixCodeApi)}${EVENTS_WEB_API_BASE}`

export const getWriteServerBaseUrl = (wixCodeApi: IWixAPI): string =>
  `${getBaseUrl(wixCodeApi, MAIN_DC_URL)}${EVENTS_WEB_API_BASE}`

export const getBaseUrl = (wixCodeApi: IWixAPI, fallback = '') => {
  if (isSSR(wixCodeApi)) {
    return parseBaseUrl(wixCodeApi).root
  } else {
    return fallback
  }
}

export const parseBaseUrl = (wixCodeApi: IWixAPI) => {
  const baseUrl = wixCodeApi.location.baseUrl
  const baseUrlParts = baseUrl.split('/')
  return {
    root: baseUrlParts.slice(0, 3).join('/'),
    relative: baseUrlParts.slice(3).join('/'),
  }
}

export const getMultilingualFullLocale = ({window: {multilingual}}: IWixAPI): string | undefined => {
  if (multilingual.isEnabled) {
    const siteLanguage = multilingual.siteLanguages.find(lang => lang.languageCode === multilingual.currentLanguage)
    return siteLanguage?.locale
  }
}

export const getLocale = (wixCodeApi: IWixAPI) =>
  getMultilingualFullLocale(wixCodeApi) || wixCodeApi.site.regionalSettings

export const getLanguage = (wixCodeApi: IWixAPI) => {
  const currentLanguage = wixCodeApi.window?.multilingual?.currentLanguage
  const language = currentLanguage || wixCodeApi.site.language

  return isLanguageBabelSupported(language) ? language : DEFAULT_LANGUAGE
}

export const getLocationQueryLang = (wixCodeApi: IWixAPI) => wixCodeApi.location.query.lang

export const getTimezone = (wixCodeApi: IWixAPI) => wixCodeApi.site.timezone || getUserTimezone() || 'UTC'

export const isMobile = (wixCodeApi: IWixAPI) => wixCodeApi.window.formFactor === 'Mobile'

export const isEditor = (wixCodeApi: IWixAPI) => wixCodeApi.window.viewMode === 'Editor'
export const isPreview = (wixCodeApi: IWixAPI) => wixCodeApi.window.viewMode === 'Preview'
export const isSite = (wixCodeApi: IWixAPI) => wixCodeApi.window.viewMode === 'Site'

export const getViewMode = (wixCodeApi: IWixAPI) =>
  (wixCodeApi.window.viewMode === 'Site' ? 'Site' : 'Editor').toLowerCase() // preview loads wrong data for Editor

export const trackEvent = (wixCodeApi: IWixAPI, eventName: ITrackEventName, params: ITrackEventParams) =>
  wixCodeApi.window.trackEvent(eventName, params)

export const getPageUrl = async (wixCodeApi: IWixAPI): Promise<string> => {
  const {url} = await wixCodeApi.site.getSectionUrl({
    sectionId: 'events',
    appDefinitionId: EVENTS_APP_ID,
  })
  return url
}

export const isSchedulePageInstalled = (wixCodeApi: IWixAPI) =>
  wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: EVENTS_APP_ID,
    sectionId: SCHEDULE_SECTION_ID,
  })

const getUserTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (e) {
    console.warn('Could not resolve Intl user timezone')
  }
}
