import {focusElement, hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import ChevronUp from 'wix-ui-icons-common/ChevronUp'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Form} from '../../../form/container'
import {Checkbox} from '../../../form/commons/checkbox'
import {DISABLED_MANDATORY_FIELDS} from '../../../form/constants'
import c from '../../../classnames.scss'
import {RegFormData} from '../../../../types'
import {TicketDetailsProps} from './interfaces'
import s from './ticket-details.scss'

export class TicketDetails extends React.Component<TicketDetailsProps> {
  componentDidUpdate(prevProps: TicketDetailsProps) {
    if (prevProps.expanded !== this.props.expanded && this.props.expanded) {
      setTimeout(() => {
        focusElement({selector: hookToAttributeSelector(DH.TICKET_DETAILS_FORM(this.props.index))})
      }, 10)
    }
  }

  submitTicketDetails = (data: RegFormData) => {
    this.props.setTicketDetails(this.props.index, data, true)
    this.props.onSubmit()
  }

  toggleUseBuyerDetails = () => this.props.setUseBuyerDetails(this.props.index, !this.props.useBuyerDetails)

  render() {
    const {details, editable, expanded, index} = this.props

    return (
      <div
        className={classNames(c.formLabelsFont, c.formTitleColor, {
          [s.editable]: editable,
          [s.expanded]: expanded,
          [s.filled]: details,
        })}
        data-hook={DH.TICKET_DETAILS_FORM(index)}
      >
        {this.renderHeader()}
        {this.renderForm()}
        <div className={s.divider} />
      </div>
    )
  }

  renderHeader() {
    const {index, ticket, editable, expanded, details, onExpand, t} = this.props

    return (
      <div className={s.header} onClick={editable ? onExpand : null}>
        <div className={s.title}>
          <div>
            {t('mobile.checkout.ticket.title', {
              index: index + 1,
              name: ticket.name,
              interpolation: {escapeValue: false},
            })}
          </div>
          {editable && (expanded ? <ChevronUp className={s.arrow} /> : <ChevronDown className={s.arrow} />)}
        </div>
        {details && !expanded && (
          <div className={s.details}>
            <div>
              {details.firstName} {details.lastName}
            </div>
            <div>{details.email}</div>
          </div>
        )}
      </div>
    )
  }

  renderForm() {
    const {formId, index, expanded, t, details, useBuyerDetails, setTicketDetails, selectedTicketsQuantity} = this.props

    return (
      expanded && (
        <div>
          <div className={s.divider} />
          {!index && (
            <div className={s.checkbox}>
              <Checkbox
                value={t('mobile.checkout.ticket.useBuyerDetails') as string}
                checked={useBuyerDetails}
                onChange={this.toggleUseBuyerDetails}
              />
            </div>
          )}
          <div className={s.form}>
            <Form
              form={formId}
              initialValues={details}
              disabledFields={!index && useBuyerDetails ? DISABLED_MANDATORY_FIELDS : emptyArr}
              onFormSubmit={this.submitTicketDetails}
              t={t}
              setTicketDetails={setTicketDetails}
              isTicketForm
              isLastTicket={index === selectedTicketsQuantity - 1}
            />
          </div>
        </div>
      )
    )
  }
}

const emptyArr = []
