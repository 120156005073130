import {isFeeAdded} from '@wix/wix-events-commons-statics'
import {calculateTicketsTotalPrice} from '../services/payment'
import {SelectedTickets} from '../types'

export const getTicketById = (tickets: wix.events.ticketing.TicketDefinition[], id: string) =>
  tickets.find(ticket => ticket.id === id)

export const getTicketOrderIndexById = (tickets: wix.events.ticketing.TicketDefinition[], id: string) =>
  tickets.findIndex(ticket => ticket.id === id)

export const areAllTicketsSoldOut = (tickets: wix.events.ticketing.TicketDefinition[]) =>
  !tickets.find((ticket: wix.events.ticketing.TicketDefinition) => !!ticket.limitPerCheckout)

export const isPaidTicketSelected = (
  tickets: wix.events.ticketing.TicketDefinition[],
  selectedTickets: SelectedTickets,
) => calculateTicketsTotalPrice(tickets, selectedTickets) > 0

export const isWixFeeTypeIsAdded = (ticket: wix.events.ticketing.TicketDefinition) =>
  isFeeAdded(ticket.wixFeeConfig?.type)
