import * as React from 'react'
import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {TicketsListContainer} from '../../tickets-list-container'
import {Ticket} from '../../ticket'
import {TicketsListProps} from '.'

export const TicketsList = ({showAccessibilityMode, places, addPlaceToBasket}: TicketsListProps) => {
  const handlePlaceClick = (clickedPlace: PlaceWithTicketInfo, selected) => {
    addPlaceToBasket({placeId: clickedPlace.id, count: selected ? 0 : 1})
  }

  return (
    <TicketsListContainer>
      {places.map((place, index) => {
        return (
          <Ticket
            showAccessibilityMode={showAccessibilityMode}
            key={place.id}
            lastTicket={index === places.length - 1}
            place={place}
            onClick={clickedPlace => handlePlaceClick(clickedPlace, place.inBasket)}
            selected={place.inBasket}
          />
        )
      })}
    </TicketsListContainer>
  )
}
