import {
  getCurrency,
  getFormattedMoney,
  WIX_EVENTS_TICKET_DEFINITION_FQDN,
  PlaceWithTicketInfo,
} from '@wix/wix-events-commons-statics'
import {createSelector} from 'reselect'
import {State} from '../types'

export const getTicketDefinitionByExternalId = (tickets: wix.events.ticketing.TicketDefinition[], externalId: string) =>
  tickets.find(ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''))

export const getPlaces = (state: State) => state.seating.places

export const getSelectedFormattedPrice = (state: State) => {
  const selectedPrice = state.seating.selectedPrice
  if (selectedPrice) {
    return getFormattedMoney({amount: selectedPrice, currency: getCurrency(state.event)})
  }
}

export const getSelectedZone = (state: State) => state.seating.selectedZone

export const getPlacesInBasket = createSelector(getPlaces, places => places.filter(place => place.inBasket))

export const getFilteredPlaces = createSelector(
  getPlaces,
  getSelectedFormattedPrice,
  getSelectedZone,
  (places, price, zone) => {
    if (price) {
      places = places.filter(place => place.ticketPrice === price)
    }

    if (zone) {
      places = places.filter(place => place.elementId === Number(zone))
    }

    return places
  },
)

export const getSelectedTicketsWithPlaces = createSelector(getPlaces, places =>
  places
    .filter(place => place.inBasket)
    .reduce((acc, place) => [...acc, ...new Array(place.quantity).fill(place)], [] as PlaceWithTicketInfo[]),
)

export const getSelectedPlace = createSelector(getPlaces, places => places.find(place => place.selected))

export const getPlaceQuantity = createSelector(
  (places: PlaceWithTicketInfo[], id: string): number => getPlaceInfo(places, id)?.quantity ?? 0,
  places => places,
)

export const getPlaceInfo = (places: PlaceWithTicketInfo[], id: string): PlaceWithTicketInfo | undefined =>
  places.find(place => place.id === id)
